import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText, FormGroup, DialogActions, Tooltip, withStyles } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import AlertArchive from './AlertArchive';
import 'react-toastify/dist/ReactToastify.css';
import { availableSurface } from "../../../utils/ParcelArea";
import Select from 'react-select';
import _ from 'lodash'

import './styles.css'
import ArrangeSeedlings from './ArrangeSeedlings';
import RemoveSort from './components/RemoveSort';

export default class AddField extends React.Component {
    constructor(props) {
        super(props);
        this.arrangementComponent = React.createRef()

        this.state = {
            data: this.props.data,
            kultura_grupa: null, cropGroupType: [],
            kultura_podgrupa: null, cropSubgroupType: [], kpg_disabled: true,
            kultura: null, cropType: [], boja: '', kultura_disabled: true,
            kultura_sorta: [], hybrydType: [], hybryd_disabled: true,
            layer: null, layerType: [],
            vrsta_proizvodnje: null, productionType: [],
            naziv: null, povrsina: null, id: null,
            semenska_proizvodnja: false, has_activity: false, geometry: false,
            povrsina: null, user_season: null, field_list: [], field: null, new: false, crop_rotation: false,
            cr_crop: null, cr_crops: [],
            crop_group: null, crops_group: [],
            crop_subgroup: null, crops_subgroup: [],
            crop: null, crops: [], crop_rotation_tip: {}, boja: null, boja_table: null, broj_cokota: null,
            archived_crop: null,
            sector: null, sectorType: [],
            podtabla: false, main_field: null, mainFieldType: [],
            glavna_tabla: false, podtabla_check: false,
            availableArea: "",
            parcels: [],
            parcel: "",
            checkBoxActive: false,
            promjenaParcele: false,
            alert: false,
            add: this.props.add, edit: this.props.edit,
            totalHybridArea: 0,
            totalSeedlings: 0,
            editGeom: false,
            arrange_seedlings: false,
            broj_redova: 0,
            raspored_redova: [],
            prazni_redovi: typeof this.props.data !== 'undefined' ? this.props.data.prazni_redovi || [] : [],
            //-----------------
            koristi_redove: false,
            isRemoveSortModalOpen: false,
            sortRemovalPendingAction: null,
            sortRemovalNewIds: [],
            disableArrange: true
        };
    };

    onInputchange(event) {
        this.setState({
            povrsina: event.target.value
        });
    }

    async showAvailableSurface(id) {
        const povrsinaParcele = this.state.parcels.find(parcel => parcel.id == id).povrsina;
        let idTable = null;
        if (this.props.data == undefined) {
            idTable = this.state.field
        }
        else {
            idTable = this.props.data.id
        }

        let preostalo = await availableSurface(id, idTable, this.state.povrsina, povrsinaParcele)

        this.setState({ availableArea: preostalo.toFixed(4), showAvaliable: true });
        if (this.props.edit && parseFloat(this.state.povrsina) >= (parseFloat(this.state.availableArea) + parseFloat(this.state.povrsina)) && this.state.promjenaParcele != true) {
            this.setState(function (state, props) { return { povrsina: parseFloat(this.state.availableArea) + parseFloat(this.state.povrsina) } })
        }
        else if (this.props.edit && parseFloat(this.state.povrsina) >= (parseFloat(this.state.availableArea) + parseFloat(this.state.povrsina)) && parseFloat(this.state.availableArea) == 0) {
            this.setState(function (state, props) { return { povrsina: 0 } })
        }
        else if (parseFloat(this.state.povrsina) >= parseFloat(this.state.availableArea) && this.state.promjenaParcele == true) {
            this.setState(function (state, props) { return { povrsina: parseFloat(this.state.availableArea).toFixed(4) } })
        }
    }

    onCheckBoxChange(e) {
        this.setState(function (state, props) { return { checkBoxActive: !state.checkBoxActive } })
        if (e.target.checked) {
            this.setState(function (state, props) {
                if (this.props.edit) {
                    return {
                        povrsina: (parseFloat(state.availableArea) + parseFloat(this.props.data.povrsina)).toFixed(4)
                    }
                }
                else {
                    return {
                        povrsina: state.availableArea
                    }
                }
            })
        }
        else {
            if (this.props.edit) {
                this.setState({
                    povrsina: this.props.data.povrsina
                })
            }
            else {
                this.setState({
                    povrsina: ""
                })
            }
        }
    }

    componentDidMount() {
        this.getByURL('crop_group/kultura_grupa_klijent', 'cropGroupType');
        this.getByURL('vrsta_proizvodnje/drop_down', 'productionType');
        this.getByURL('sektor/drop_down', 'sectorType');
        this.getByURL('field/drop_down', 'mainFieldType');
        this.getByURL('layer/drop_down', 'layerType');

  
        axios.get('api/parcela/parcel_list_drop_down').then(res => {
            this.setState({ parcels: res.data.data })
            if (this.props.edit && this.props.data.parcela_id != null) { //u slucaju edita tabele koja nema parcelu iz nekog razloga
                this.showAvailableSurface(this.props.data.parcela_id)
            }
        })

        axios.get('api/season/activity_season')
            .then(res => {
                this.setState({ user_season: res.data.data[0].id_default_sezona }, () => {
                    axios.get('api/layer/layer_by_season', { params: { season: this.state.user_season } })
                        .then(res => {
                            this.setState({ layer: res.data.data[0].id, loading: false }, () => {
                                if (this.props.povrsina) {
                                    this.setState({ povrsina: this.props.povrsina });

                                    let seasons = [];
                                    if (this.props.seasons) this.props.seasons.map((item, i) => { seasons.push(item.value) })

                                    axios.get('api/field/field_list_no_geom', { params: { seasons } })
                                        .then(res => {
                                            let field_list = [];
                                            for (let i = 0; i < res.data.data.length; i++) {
                                                field_list.push({ id: res.data.data[i].id, name: res.data.data[i].naziv, povrsina: res.data.data[i].povrsina });
                                            }
                                            this.setState({ field_list });
                                        })
                                        .catch(err => { console.log(err) })
                                }

                                if (this.state.edit === true) {
                                    let has_activity = false;
                                    if (this.props.data.has_activity === "0") has_activity = false;
                                    else has_activity = true;

                                    let geometry = false;
                                    if (this.props.data.geom_mobile === null) geometry = false;
                                    else geometry = true;

                                    axios.get('api/crop_subgroup/kultura_podgrupa_klijent',
                                        { params: { id_grupa: this.props.data.id_kultura_grupa } }
                                    )
                                        .then(res => { this.setState({ cropSubgroupType: res.data.data, kpg_disabled: false }); })
                                        .catch(err => { console.log(err) })

                                    axios.get('api/crop/kultura_klijent',
                                        { params: { id_podgrupa: this.props.data.id_kultura_podgrupa } }
                                    )
                                        .then(res => { this.setState({ cropType: res.data.data, kultura_disabled: false }); })
                                        .catch(err => { console.log(err) })

                                    axios.get('api/crop/hybryd_drop_down', { params: { id_kultura: this.props.data.id_kultura } })
                                        .then(res => { this.setState({ hybrydType: res.data.data, hybryd_disabled: false }); })
                                        .catch(err => { console.log(err) })


                                    this.setState({
                                        boja: this.props.data.boja,
                                        geometry: geometry,
                                        has_activity: has_activity,
                                        id: this.props.data.id,
                                        kultura: this.props.data.id_kultura,
                                        kultura_grupa: this.props.data.id_kultura_grupa,
                                        kultura_podgrupa: this.props.data.id_kultura_podgrupa,
                                        // kultura_sorta: this.props.data.id_kultura_sorta,
                                        // kultura_sorta: [],
                                        kultura_sorta: this.props.data.sorte.map(sorta => {
                                            return {
                                                naziv: sorta.naziv,
                                                id_kultura_sorta: sorta.id_kultura_sorta,
                                                povrsina: parseFloat(sorta.povrsina),
                                                broj_sadnica: sorta.broj_sadnica === null ? null : parseFloat(sorta.broj_sadnica),
                                                raspored_redova: typeof sorta.raspored_redova === 'undefined'  || sorta.raspored_redova === null ? [] : sorta.raspored_redova
                                            }
                                        }),
                                        layer: this.props.data.id_layer,
                                        vrsta_proizvodnje: this.props.data.id_vrsta_proizvodnje,
                                        naziv: this.props.data.naziv,
                                        povrsina: this.props.data.povrsina,
                                        semenska_proizvodnja: this.props.data.semenska_proizvodnja,
                                        podtabla_check: this.props.data.deo_table,
                                        podtabla: this.props.data.deo_table,
                                        sector: this.props.data.id_sektor,
                                        main_field: this.props.data.id_glavna_tabla,
                                        broj_cokota: this.props.data.broj_cokota,
                                        parcel: this.props.data.parcela_id,
                                        broj_redova: typeof this.props.data !== 'undefined' ? (this.props.data.sorte.reduce((sum, sorta) => {
                                            return sorta.raspored_redova !== undefined ? sum + sorta.raspored_redova.length : sum
                                        }, 0) +   (typeof this.props.data.prazni_redovi !== 'undefined' ? this.props.data.prazni_redovi.length : 0 )  ) : 0,
                                        has_radni_nalog_redovi: this.props.data.has_radni_nalog_redovi,
                                        koristi_redove: this.props.data.koristi_redove
                                    }, () => {
                                        if (this.state.kultura !== null) {
                                            axios.get('api/crop/kultura_boja', { params: { id_crop: this.state.kultura } })
                                                .then(res => {
                                                    if (res.data.data[0].boja_table !== null) {
                                                        function hexToRgb(h) { return ['0x' + h[1] + h[2] | 0, '0x' + h[3] + h[4] | 0, '0x' + h[5] + h[6] | 0] };
                                                        let boja = hexToRgb(res.data.data[0].boja_table);
                                                        boja = boja[0] + ', ' + boja[1] + ', ' + boja[2];
                                                        this.setState({ boja, boja_table: res.data.data[0].boja_table });
                                                    }
                                                    else {
                                                        this.setState({ boja: null, boja_table: null })
                                                    }
                                                })
                                                .catch(err => { console.log(err) })
                                        };

                                        if  (this.state.kultura_sorta.length > 0 
                                        //     && this.state.kultura_grupa == "161" ||
                                        // this.state.kultura_grupa == "6" ||
                                        // this.state.kultura_grupa == "169" ||
                                        // this.state.kultura_grupa == "201" ||
                                        // this.state.kultura_grupa == "203" ||
                                        // this.state.kultura_grupa == "204" ||
                                        // this.state.kultura_grupa == "210" ||
                                        // this.state.kultura_grupa == "211"
                                    ) {
                                            console.log("mozda edit", this.state.broj_redova);
                                            if(this.state.broj_redova > 0){
                                                this.setState({
                                                    disableArrange: false
                                                })
                                            }
                                            this.makeEditRows()
                                        }

                                        this.calculateTotalHybridArea();
                                        // this.calculateTotalSeedlings();

                                        axios.get('api/field/main_field_check', { params: { id: this.state.id } })
                                            .then(res => { if (res.data.total > 0) this.setState({ glavna_tabla: true }); })
                                            .catch(err => { console.log(err) })
                                    });
                                }
                            });
                        })
                        .catch(err => { console.log(err) })
                })
            })
            .catch(err => { console.log(err) })
    };

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.kultura_sorta, this.state.kultura_sorta)) {
            this.checkRowsForSorts()
        }
    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    };
    archiveById(fieldId) {

        let arciveCheck = true;

        axios.post(`api/field/check_if_in_locked_activity`, { id: fieldId })
            .then(res => {

                let array = res.data.data
                array.forEach(element => {
                    //za status zavrsen id === 1
                    if (element.id_status !== 1) {
                        arciveCheck = false
                    }
                })
                if (arciveCheck) {
                    axios.post(`api/field/archive_by_id`, { id: fieldId })
                        .then(res => {
                            this.setState({ alert: true });
                            console.log(this.state.alert)
                        })
                        .catch(err => {
                            this.setState({ delete: false });
                            console.log('Error: ', err);
                        })
                } else {
                    wentWrong("There is ongoing activity on this field. Field cannot be archived.")
                }
            })

            .catch(err => {
                console.log('Error: ', err);
            })
    }

    kulturaSortaFields(item) {
        return {
            id_kultura_sorta: item.id_kultura_sorta,
            povrsina: item.povrsina,
            broj_sadnica: item.broj_sadnica,
            broj_redova: item.raspored_redova === undefined || item.raspored_redova === null ? null : item.raspored_redova.length,
            raspored_redova: item.raspored_redova
        }
    }

    hasEmptySortArea() {
        return this.state.kultura_sorta.some(ks => parseFloat(ks.povrsina) === 0 || ks.povrsina === undefined || ks.povrsina === null)
    }

    addField() {
        if (this.state.naziv && this.state.povrsina || this.state.field && this.state.povrsina) {
            let warningMessage = 'Area equals 0!'
            if(this.hasEmptySortArea()) {
                warningMessage = 'Sort area equals 0!'
            }

            if (this.state.povrsina != 0 && !this.hasEmptySortArea()) {
                const { onSave, handleClose } = this.props;

                let data = {};

                if (this.state.edit === true) axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 14 } })
                else axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 9 } })  

                if (this.state.edit === false && this.state.field === null && this.props.povrsina === undefined) {
                    data = {
                        id_kultura_grupa: (this.state.kultura_grupa),
                        id_kultura_podgrupa: (this.state.kultura_podgrupa),
                        id_kultura: (this.state.kultura),
                        // id_kultura_sorta: (this.state.kultura_sorta),
                        id_kultura_sorta: (6456),
                        //                         kultura_sorta_ids: this.state.kultura_sorta.map
                        // :                       kultura_sorta_nazivi:

                        // OVDE NI PRAZAN ARRAY NE PROLAZI 
                        // kulture_sorta: [],
                        kultura_sorta: this.state.kultura_sorta
                            .filter(item => item.povrsina !== 0 && item.povrsina !== null)
                            .map(item => this.kulturaSortaFields(item)),
                        // kultura_sorta: this.state.kultura_sorta,
                        id_layer: (this.state.layer),
                        naziv: this.state.naziv,
                        povrsina: this.state.povrsina,
                        semenska_proizvodnja: this.state.semenska_proizvodnja,
                        id_vrsta_proizvodnje: (this.state.vrsta_proizvodnje),
                        id_sektor: this.state.sector,
                        deo_table: this.state.podtabla,
                        id_glavna_tabla: this.state.main_field,
                        id_parcela: this.state.parcel,
                        id_predkultura: this.state.archived_crop,
                        broj_cokota: this.state.broj_cokota,
                        prazni_redovi: this.state.prazni_redovi,
                        broj_redova: this.state.broj_redova,
                        koristi_redove: this.state.koristi_redove
                    };

                    let boja = null;
                    if (this.state.boja_table !== null) boja = this.state.boja_table;
                    data.boja = boja;

                    axios.post('api/field/create', data)
                        .then(res => {
                            wentRight(i18n.t('Successfully added a field'));
                            handleClose();
                        })
                        .catch(err => {
                            console.log(err);
                            wentWrong(i18n.t('Something went wrong, please try again!'))
                        })
                }
                else if (this.state.edit === true && this.state.field === null && this.props.povrsina === undefined) {
                    data = {
                        id: (this.props.data.id),
                        id_kultura_grupa: (this.state.kultura_grupa),
                        id_kultura_podgrupa: (this.state.kultura_podgrupa),
                        id_kultura: (this.state.kultura),
                        // id_kultura_sorta: (this.state.kultura_sorta),
                        id_kultura_sorta: (6456),
                        // kultura_sorta: this.state.kultura_sorta,
                        kultura_sorta: this.state.kultura_sorta
                            .filter(item => item.povrsina !== 0 && item.povrsina !== null)
                            .map(item => this.kulturaSortaFields(item)),

                        id_layer: (this.state.layer),
                        naziv: this.state.naziv,
                        povrsina: this.state.povrsina,
                        semenska_proizvodnja: this.state.semenska_proizvodnja,
                        id_vrsta_proizvodnje: (this.state.vrsta_proizvodnje),
                        id_sektor: this.state.sector,
                        deo_table: this.state.podtabla,
                        id_glavna_tabla: this.state.main_field,
                        id_parcela: this.state.parcel,
                        broj_cokota: this.state.broj_cokota,
                        prazni_redovi: this.state.prazni_redovi,
                        broj_redova: this.state.broj_redova,
                        koristi_redove: this.state.koristi_redove
                    };

                    let boja = null;
                    if (this.state.boja_table !== null) boja = this.state.boja_table;
                    data.boja = boja;

                    axios.post('api/field/create', data)
                        .then(res => {
                            wentRight(i18n.t('Successfully added a field'));
                            handleClose();
                        })
                        .catch(err => {
                            console.log(err);
                            wentWrong(i18n.t('Something went wrong, please try again!'))
                        })
                }

                if (this.props.povrsina !== undefined && this.state.naziv !== null && this.state.field === null) {
                    data = {
                        id_kultura_grupa: (this.state.kultura_grupa),
                        id_kultura_podgrupa: (this.state.kultura_podgrupa),
                        id_kultura: (this.state.kultura),
                        // id_kultura_sorta: (this.state.kultura_sorta),
                        // MORA INTEGER DA BUDE HARDKODIRAN DA BI PROLAZIO POST REQUEST 
                        id_kultura_sorta: 4567,

                        kultura_sorta: this.state.kultura_sorta
                            .filter(item => item.povrsina !== 0 && item.povrsina !== null)
                            .map(item => this.kulturaSortaFields(item)),

                        id_layer: (this.state.layer),
                        naziv: this.state.naziv,
                        povrsina: this.state.povrsina,
                        semenska_proizvodnja: this.state.semenska_proizvodnja,
                        id_vrsta_proizvodnje: (this.state.vrsta_proizvodnje),
                        id_sektor: this.state.sector,
                        deo_table: this.state.podtabla,
                        id_glavna_tabla: this.state.main_field,
                        id_parcela: this.state.parcel,
                        broj_cokota: this.state.broj_cokota,
                        prazni_redovi: this.state.prazni_redovi,
                        broj_redova: this.state.broj_redova,
                        koristi_redove: this.state.koristi_redove
                    };

                    let boja = null;
                    if (this.state.boja_table !== null) boja = this.state.boja_table;
                    data.boja = boja;
                    onSave(data);
                }
                else if (this.props.povrsina !== undefined && this.state.naziv === null && this.state.field !== null) {
                    let field_obj = {};
                    for (let i = 0; i < this.state.field_list.length; i++) {
                        if (Number(this.state.field) === this.state.field_list[i].id) {
                            field_obj = this.state.field_list[i];
                        }
                    }

                    data = {
                        id: field_obj.id,
                        id_kultura_grupa: (this.state.kultura_grupa),
                        id_kultura_podgrupa: (this.state.kultura_podgrupa),
                        id_kultura: (this.state.kultura),
                        // id_kultura_sorta: (this.state.kultura_sorta),
                        // MORA INTEGER DA BUDE HARDKODIRAN DA BI PROLAZIO POST REQUEST 
                        id_kultura_sorta: 4567,

                        kultura_sorta: this.state.kultura_sorta
                            .filter(item => item.povrsina !== 0 && item.povrsina !== null)
                            .map(item => this.kulturaSortaFields(item)),
                        id_layer: (this.state.layer),
                        naziv: field_obj.naziv,
                        povrsina: this.state.povrsina,
                        semenska_proizvodnja: this.state.semenska_proizvodnja,
                        id_vrsta_proizvodnje: (this.state.vrsta_proizvodnje),
                        id_sektor: this.state.sector,
                        deo_table: this.state.podtabla,
                        id_glavna_tabla: this.state.main_field,
                        id_parcela: this.state.parcel,
                        broj_cokota: this.state.broj_cokota,
                        prazni_redovi: this.state.prazni_redovi,
                        broj_redova: this.state.broj_redova,
                        koristi_redove: this.state.koristi_redove
                    };

                    let boja = null;
                    if (this.state.boja_table !== null) boja = this.state.boja_table;
                    data.boja = boja;
                    onSave(data);
                }
            } else wentWrong(warningMessage);
        } else wentWrong('Please enter all required fields!');
    };

    updateGeom() {
        if (this.state.field) {
            const { onUpdateGeom, handleClose } = this.props;
            onUpdateGeom(this.state.field)
        }
    }
    getCropRotation = (id_kultura, id_predkultura) => {
        axios.get('api/field/getPlodored', { params: { id_kultura: id_kultura, id_predkultura: id_predkultura } })
            .then(res => { this.setState({ crop_rotation_tip: res.data.data }); })
            .catch(err => console.log(err))
    };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    addAfterCulture = (data) => {
        delete data.id;
        this.setState({ add: true })
        this.setState({ edit: false })
        this.setState({ archived_crop: data.id_kultura })
        this.setState({ cr_crop: data.id_kultura })
        this.setState({ kultura_grupa: '' })
        this.setState({ kultura_podgrupa: '' })
        this.setState({ kultura: '' })
        this.setState({ kultura_sorta: [] })
    }

    closeEditDialog = () => {
        this.props.handleClose()
    }
    handleCloseAlert = () => {
        this.setState({ alert: !this.state.alert });
        this.props.handleCloseArchived();
    }

    updatePovrsina = (sortaId, newValue) => {
        this.setState(prevState => ({
            kultura_sorta: prevState.kultura_sorta.map(sorta => {
                if (sorta.id_kultura_sorta == sortaId) {


                    const maxAllowedValue = parseFloat((((this.state.povrsina === null || this.state.povrsina === "") ? 0 : parseFloat(this.state.povrsina)) - (this.state.totalHybridArea - sorta.povrsina)).toFixed(4));
                    const updatedValue = newValue > maxAllowedValue ? maxAllowedValue : newValue;

                    return {
                        ...sorta,
                        povrsina: updatedValue,
                    };
                }
                return sorta;
            }),
        }), () => {
            this.calculateTotalHybridArea();
        });
    };

    calculateTotalHybridArea = () => {
        let ukupnaPovrsinaPodHibridimaSortama = 0;
        this.state.kultura_sorta.forEach(sorta => {
            ukupnaPovrsinaPodHibridimaSortama += (sorta.povrsina === null ? 0 : sorta.povrsina);
        })
        this.setState({ totalHybridArea: ukupnaPovrsinaPodHibridimaSortama })
    }

    updateBrojRedova = (newValue) => {
        const updatedValue = (newValue === null || newValue === "") ? 0 : newValue;
        this.makeEditRows(updatedValue)
        this.setState({broj_redova: updatedValue})
    };

    saveSeedlingsArrangement = (noviRedovi) => {
        this.setState({
            raspored_redova: noviRedovi
        })

        let prazni_redovi = []

        let kultura_sorta_edit = this.state.kultura_sorta;
        kultura_sorta_edit.forEach(ks => ks.raspored_redova = [])

        noviRedovi.forEach(noviRed => {
            if (noviRed.id_kultura_sorta !== null) {
                let updateKulturaSorta = kultura_sorta_edit.find(ks => ks.id_kultura_sorta == noviRed.id_kultura_sorta)

                if (updateKulturaSorta !== undefined){
                     updateKulturaSorta.raspored_redova.push(noviRed)
                }
                else {
                    noviRed.id_kultura_sorta = null
                    noviRed.broj_sadnica = 0
                    prazni_redovi.push(noviRed)
                }
            }
            else {
                prazni_redovi.push(noviRed)
            }
        })

        this.setState({ kultura_sorta: kultura_sorta_edit })

        this.setState({ prazni_redovi: prazni_redovi })
        this.state.arrange_seedlings = false
    }

    getKulturaSortaForId = (kultura_sorta_id) => {
        return this.state.kultura_sorta.find(ks => ks.id === kultura_sorta_id)
    }

    calculateTotalHybridArea = () => {
        let ukupnaPovrsinaPodHibridimaSortama = 0;
        this.state.kultura_sorta.forEach(sorta => {
            ukupnaPovrsinaPodHibridimaSortama += (sorta.povrsina === null ? 0 : sorta.povrsina);
        })
        this.setState({ totalHybridArea: ukupnaPovrsinaPodHibridimaSortama })
    }

    calculateTotalSeedlings = () => {
        let ukupno = 0;
        this.state.kultura_sorta.forEach(sorta => {
            ukupno += (sorta.broj_sadnica === null ? 0 : sorta.broj_sadnica);
        })
        this.setState({ totalSeedlings: ukupno });
    }

    makeEditRows(brojRedova) {
        let raspored_redova = []
        this.state.kultura_sorta.forEach(ks => {
            ks.raspored_redova.forEach(red =>
                raspored_redova.push(red)
            )
        })

        this.state.prazni_redovi.forEach(red => {
            raspored_redova.push(red)
        })

        raspored_redova = raspored_redova.sort((a, b) => {
            return a.broj_reda - b.broj_reda
        })

        if (brojRedova) {
            let lastRowNo = raspored_redova.length > 0 ? raspored_redova[raspored_redova.length - 1].broj_reda : 0
            let modifiedRowsEdit = raspored_redova.map(x => x)

            if (raspored_redova.length < brojRedova) {
                let rowsToFill = brojRedova - raspored_redova.length

                while (rowsToFill > 0) {
                    modifiedRowsEdit.push({
                        broj_reda: lastRowNo + 1,
                        broj_sadnica: 0,
                        id_kultura_sorta: null
                    })

                    lastRowNo++
                    rowsToFill--
                }
            }

            else if (raspored_redova.length > brojRedova) {
                modifiedRowsEdit = raspored_redova.slice(0, brojRedova)
            }
            this.saveSeedlingsArrangement(modifiedRowsEdit)
        }
        else {
            this.saveSeedlingsArrangement(raspored_redova)
        }
    }

    toggleKoristiRedove = () => {
        this.setState(prevState => ({
            koristi_redove: !prevState.koristi_redove
        }));
    }

    checkRowsForSorts() {
        let prazni_redovi = []
        this.state.raspored_redova.forEach(red => {
            if (!this.state.kultura_sorta.some(sorta => sorta.id_kultura_sorta === red.id_kultura_sorta)) {
                red.id_kultura_sorta = null
                red.broj_sadnica = 0
                prazni_redovi.push(red)
            }
        })

        this.setState({ prazni_redovi: prazni_redovi })
    }

    CustomTooltip = withStyles({
        tooltip: {
            fontSize: '14px'
        }
    })(Tooltip)

    render() {
        const { CustomTooltip } = this
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.state.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth={this.state.arrange_seedlings ? "md" : "sm"}
                    aria-labelledby="responsive-dialog-title">

                    <DialogTitle disableTypography={true} style={{ background: '#04764e', display: 'flex' }} className="responsive-dialog-title">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {this.state.kultura &&
                                <div style={{ display: 'flex', marginRight: '10px' }}>
                                    <div style={{
                                        width: '50px', height: '25px', alignSelf: 'center', marginLeft: '5px',
                                        border: this.state.boja === null ? `3px rgb(70, 146, 251) solid` : `3px rgb(${this.state.boja}) solid`,
                                        backgroundColor: this.state.boja === null ? `rgba(70, 146, 251, 0.3)` : `rgba(${this.state.boja}, 0.3)`
                                    }} />
                                </div>
                            }

                            <div style={{}}>{this.state.edit ? i18n.t('Edit field') : i18n.t('Add field')}: {this.state.naziv ? this.state.naziv : ''} {this.state.glavna_tabla ? '(' + i18n.t('Main field') + ')' : ''} {this.state.podtabla_check ? '(' + i18n.t('Part of the field') + ')' : ''} 
                                {this.state.arrange_seedlings ? ' - ' + i18n.t('Arrange seedlings') : ''}
                            </div>
                        </div>
                        {this.state.field_list.length > 0 &&
                            < div style={{ display: 'flex' }}>

                                <Typography style={{ fontSize: '18px', color: 'white', alignSelf: 'center' }}>{i18n.t('New')}</Typography>
                                <Switch
                                    checked={this.state.new}
                                    onClick={(e) => {
                                        this.setState({ new: !this.state.new, editGeom: !this.state.editGeom });
                                        if (this.state.naziv === null) {
                                            this.setState({ field: null });
                                        }
                                        else this.setState({ naziv: null });
                                    }}
                                    color="default"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography style={{ fontSize: '18px', color: 'white', alignSelf: 'center' }}>{i18n.t('Existing')}</Typography>
                            </div>
                        }
                        <IconButton onClick={
                            () => {
                                if (this.state.arrange_seedlings) {
                                    this.setState({
                                        arrange_seedlings: false
                                    })
                                    //this.arrangementComponent.current.saveSeedlingsArrangement()
                                }
                                else {
                                    this.props.handleClose()
                                }                               
                            }
                        }>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>


                    </DialogTitle>



                    <DialogContent>

                    { this.state.arrange_seedlings === false ? <>
                        {this.state.new === false &&
                            <TextField required
                                label={i18n.t('Title')}
                                value={this.state.naziv}
                                style={{ width: '532px', marginBottom: '10px', marginTop: '10px', marginLeft: '10px' }}
                                InputLabelProps={{ shrink: this.state.naziv ? true : false }}
                                onChange={(e) => { this.setState({ naziv: e.target.value, field: null }); }}
                                disabled={this.props.isPreview} >
                            </TextField>
                        }
                        {!this.state.new &&
                            <TextField required
                                label={i18n.t('Parcel')}
                                select
                                value={this.state.parcel}
                                SelectProps={{ native: true, }}
                                style={{ width: '532px', marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}
                                onChange={(e) => {
                                    this.state.promjenaParcele = true
                                    this.setState({ parcel: e.target.value }, () => console.log("this.state.parcel : ", this.state.parcel, typeof this.state.parcel))
                                    this.showAvailableSurface(e.target.value)
                                    this.setState({ checkBoxActive: false })
                                    if (this.props.edit) {
                                        this.props.data.povrsina = 0
                                    }

                                }}
                                disabled={this.props.isPreview} >
                                <option value='' disabled hidden />
                                {this.state.parcels.map((parcel) => <option key={parcel.id} value={parcel.id}>{parcel.broj}</option>)}
                            </TextField>
                        }
                        {this.state.povrsina !== null && this.state.editGeom &&
                            <div>
                                <TextField
                                    label={i18n.t('Save geometry to field')}
                                    select
                                    value={this.state.field}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={{ shrink: this.state.field ? true : false }}
                                    style={{ width: '532px', marginBottom: '10px', marginLeft: '10px', marginTop: '10px' }}
                                    onChange={(e) => { this.setState({ field: e.target.value, naziv: null }); }}
                                    disabled={this.props.isPreview}>
                                    <option value='' />
                                    {this.state.field_list.map((fl) => { return <option key={fl.id} value={fl.id}>{fl.name} ({fl.povrsina} ha)</option> })}
                                </TextField>
                            </div>
                        }
                        {!this.state.new &&
                                <div style={{ display: 'flex' }}>
                                    <div>
                                        <TextField required
                                            type="number"
                                            pattern="[0-9]*"
                                            label={i18n.t('Land area')}
                                            value={this.state.povrsina}
                                            InputLabelProps={{ shrink: !!this.state.povrsina }}
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    max: this.props.edit ? (parseFloat(this.state.availableArea) + parseFloat(this.props.data.povrsina)) : this.state.availableArea
                                                }
                                            }
                                            }
                                            style={{ width: '256px', marginBottom: '10px', marginTop: '10px', marginLeft: '10px' }}
                                            onChange={(e) => {
                                                if (e.target.value >= 0) {
                                                    if (this.props.edit) {
                                                        this.setState({ povrsina: (parseFloat(e.target.value == "" ? 0 : e.target.value).toFixed(4) <= parseFloat(parseFloat(this.state.availableArea) + parseFloat(this.props.data.povrsina))) ? parseFloat(e.target.value) : parseFloat(this.state.availableArea) + parseFloat(this.props.data.povrsina) });
                                                    }
                                                    else {
                                                        this.setState({ povrsina: (parseFloat(e.target.value == "" ? 0 : e.target.value) <= parseFloat(this.state.availableArea)) ? e.target.value : this.state.availableArea });
                                                    }
                                                }
                                                if (this.state.kultura_sorta.length > 0) {
                                                    this.setState({
                                                        kultura_sorta: this.state.kultura_sorta.map(sorta => {
                                                            return {
                                                                ...sorta,
                                                                povrsina: 0,
                                                            }
                                                        })
                                                    }, () => this.calculateTotalHybridArea());

                                                }
                                            }}
                                            disabled={this.props.isPreview}></TextField>
                                        <label style={{ display: 'block', width: '256px', marginTop: '-10px', marginLeft: '10px', marginBottom: '-5px' }}>
                                            {i18n.t('Available surface') + ': ' + this.state.availableArea + '(ha)'}
                                        </label>
                                    </div>
                                    {!this.state.new && <div style={{ width: '256px', marginBottom: '10px', marginTop: '10px', marginLeft: '10px' }}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox
                                                checked={this.state.checkBoxActive} onChange={(e) => this.onCheckBoxChange(e)}></Checkbox>} label={i18n.t('Use whole field')}
                                                style={{ width: '256px', marginTop: '10px', marginLeft: '10px', marginBottom: '5px', }}
                                                disabled={this.props.isPreview}></FormControlLabel>
                                        </FormGroup>
                                    </div>
                                    }
                                </div>}
                            {!this.state.new && <>
                                <div className='crop_select_wrapper'>

                                    <TextField
                                        label={i18n.t('Type of production')}
                                        select
                                        value={this.state.vrsta_proizvodnje}
                                        SelectProps={{ native: true }}
                                        InputLabelProps={{ shrink: this.state.vrsta_proizvodnje ? true : false }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ vrsta_proizvodnje: e.target.value }); }}
                                        disabled={this.props.isPreview} >
                                        <option value='' />
                                        {this.state.productionType.map((pt) => <option key={pt.id} value={pt.id}>{pt.naziv}</option>)}
                                    </TextField>
                                    <div style={{ display: 'flex' }}>
                                        <div className='select-wrapper'>
                                            <TextField
                                                label={i18n.t('Crop group')}
                                                select
                                                value={this.state.kultura_grupa}
                                                SelectProps={{ native: true }}
                                                InputLabelProps={{ shrink: this.state.kultura_grupa ? true : false }}
                                                style={{
                                                    width: '157px', marginBottom: '10px',
                                                    overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'
                                                }}
                                                onChange={(e) => {
                                                    this.setState({ kultura_grupa: e.target.value }, () => {
                                                        axios.get('api/crop_subgroup/kultura_podgrupa_klijent',
                                                            { params: { id_grupa: this.state.kultura_grupa } }
                                                        )
                                                            .then(res => { this.setState({ cropSubgroupType: res.data.data, kpg_disabled: false }); })
                                                            .catch(err => { console.log(err) })

                                                        this.setState({ kultura_podgrupa: '', kultura: '', kultura_sorta: [], kultura_disabled: true })
                                                    });
                                                }}
                                                disabled={this.props.isPreview} >
                                                <option value='' />
                                                {this.state.cropGroupType.map((cg) => <option key={cg.id} value={cg.id}>{cg.naziv}</option>)}
                                            </TextField>
                                        </div>


                                        <TextField
                                            label={i18n.t('Crop subgroup')}
                                            select
                                            disabled={this.state.kpg_disabled || this.props.isPreview}
                                            value={this.state.kultura_podgrupa}
                                            SelectProps={{ native: true }}
                                            InputLabelProps={{ shrink: this.state.kultura_podgrupa ? true : false }}
                                            style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                            onChange={(e) => {
                                                if (e.target.value === '') {
                                                    this.setState({ kultura_podgrupa: e.target.value, kultura_disabled: true, hybryd_disabled: true, kultura: '', kultura_sorta: [] })
                                                } else {
                                                    this.setState({ kultura_podgrupa: e.target.value }, () => {
                                                        axios.get('api/crop/kultura_klijent',
                                                            { params: { id_podgrupa: this.state.kultura_podgrupa } }
                                                        )
                                                            .then(res => { this.setState({ cropType: res.data.data, kultura_disabled: false, kultura: '' }); })
                                                            .catch(err => { console.log(err) })
                                                    });
                                                }



                                            }} >
                                            <option value='' />
                                            {this.state.cropSubgroupType.map((csg) => <option key={csg.id} value={csg.id}>{csg.naziv}</option>)}
                                        </TextField>
                                        <TextField
                                            label={i18n.t('Crop')}
                                            select
                                            disabled={this.state.kultura_disabled ||  this.props.isPreview}
                                            value={this.state.kultura}
                                            SelectProps={{ native: true }}
                                            InputLabelProps={{ shrink: this.state.kultura ? true : false }}
                                            style={{ width: '256px', marginLeft: '10px' }}
                                            onChange={(e) => {
                                                if (e.target.value === '') { this.setState({ hybryd_disabled: true }) }
                                                this.setState({ kultura: e.target.value, kultura_sorta: [], totalSeedlings: 0}, () => {
                                                    axios.get('api/crop/kultura_boja', { params: { id_crop: this.state.kultura } })
                                                        .then(res => {
                                                            if (res.data.data[0].boja_table !== null) {
                                                                function hexToRgb(h) { return ['0x' + h[1] + h[2] | 0, '0x' + h[3] + h[4] | 0, '0x' + h[5] + h[6] | 0] };
                                                                let boja = hexToRgb(res.data.data[0].boja_table);
                                                                boja = boja[0] + ', ' + boja[1] + ', ' + boja[2];
                                                                this.setState({ boja, boja_table: res.data.data[0].boja_table });
                                                            }
                                                            else {
                                                                this.setState({ boja: null, boja_table: null })
                                                            }
                                                        })
                                                        .catch(err => { console.log(err) })
                                                    axios.get('api/crop/hybryd_drop_down', { params: { id_kultura: this.state.kultura } })
                                                        .then(res => { this.setState({ hybrydType: res.data.data, hybryd_disabled: false }); })
                                                        .catch(err => { console.log(err) })
                                                });
                                            }}>
                                            <option value='' />
                                            {this.state.cropType.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
                                        </TextField>
                                    </div>

                                    <div style={{ width: '100%', marginLeft: '0px', display: 'inline-block' }}>
                                        <label style={{ fontSize: '12px', color: '#0000008a', opacity: `${this.state.kultura_sorta.length > 0 ? 1 : 0}` }} htmlFor="">{i18n.t('Sort/Hybrid')}</label>
                                        <Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            isDisabled={this.state.hybryd_disabled || this.props.isPreview}
                                            value={this.state.kultura_sorta.map(item => { return { label: item.naziv, value: item.id_kultura_sorta } })}
                                            maxMenuHeight={200}
                                            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                                            onChange={(selectedOptions) => {
                                                const existingIDs = this.state.kultura_sorta.map(i => i.id);

                                                if(selectedOptions.length === 0){
                                                    this.setState({ koristi_redove: false })
                                                }

                                                if (selectedOptions.length > this.state.kultura_sorta.length) {

                                                    selectedOptions.forEach(option => {
                                                        if (!existingIDs.includes(option.value)) {

                                                            // if (this.state.kultura_grupa == "161" ||
                                                            //     this.state.kultura_grupa == "6" ||
                                                            //     this.state.kultura_grupa == "169" ||
                                                            //     this.state.kultura_grupa == "201" ||
                                                            //     this.state.kultura_grupa == "203" ||
                                                            //     this.state.kultura_grupa == "204" ||
                                                            //     this.state.kultura_grupa == "210" ||
                                                            //     this.state.kultura_grupa == "211"
                                                            // ) {
                                                                this.setState({
                                                                    kultura_sorta: [...this.state.kultura_sorta, {
                                                                        naziv: option.label,
                                                                        id_kultura_sorta: option.value,
                                                                        povrsina: null,
                                                                        broj_sadnica: null,
                                                                        broj_redova: null,
                                                                        raspored_redova: []
                                                                    }]
                                                                });
                                                            // } else {

                                                            //     this.setState({
                                                            //         kultura_sorta: [...this.state.kultura_sorta, {
                                                            //             naziv: option.label,
                                                            //             id_kultura_sorta: option.value,
                                                            //             povrsina: null,
                                                            //         }]


                                                            //     });
                                                            // }
                                                        }

                                                    })
                                                }

                                                if (selectedOptions.length < this.state.kultura_sorta.length) {
                                                    const existingIDs = selectedOptions.map(option => option.value);
                                                    const initialKulturaSortaIDs = this.state.kultura_sorta.map(ks => ks.id_kultura_sorta)
                                                    const obrisana_kultura_sorta_id = initialKulturaSortaIDs.filter(id => !existingIDs.some(eid => eid === id))[0]
                                                   
                                                    const deleteSort =  newIDs => {
                                                        this.state.kultura_sorta.forEach(sorta => {
                                                            if (!newIDs.includes(sorta.id_kultura_sorta)) {
                                                                let newArray = this.state.kultura_sorta.filter(item => item.id_kultura_sorta !== sorta.id_kultura_sorta)
                                                                this.setState({ kultura_sorta: newArray }, () => {
                                                                    this.calculateTotalHybridArea();
                                                                    // this.calculateTotalSeedlings();
                                                                });
                                                            }
                                                        })
                                                    }

                                                    if (this.state.raspored_redova.some(red => red.id_kultura_sorta === obrisana_kultura_sorta_id)) {
                                                        this.setState({ sortRemovalNewIds: existingIDs })
                                                        this.setState({
                                                            sortRemovalPendingAction: deleteSort
                                                        })
                                                        this.setState({
                                                            isRemoveSortModalOpen: true
                                                        })
                                                    }
                                                    else {
                                                        deleteSort(existingIDs)
                                                    }
                                                }
                                            }}
                                            options={this.state.hybrydType.map((item) => { return { label: item.naziv, value: item.id } })}
                                            placeholder={i18n.t('Sort/Hybrid')}
                                        />
                                        <div style={{ marginTop: '20px' }}>
                                {this.state.kultura_sorta.length > 0 && <div style={{ 
                                    borderBottom: '2px solid rgb(118, 118, 118)',
                                     paddingBottom: '10px',
                                     fontSize: '15px',
                                     display: 'flex',
                                     alignItems: 'center' }}>
                                    <div style={{ width: '200px'}}>{i18n.t('Naziv')}</div>
                                    <div style={{ width: '100px'}}>{i18n.t('Area')}</div>     
                                        {
                                            (this.state.kultura_sorta.length > 0
                                                //  && this.state.kultura_grupa == "161" ||
                                                // this.state.kultura_grupa == "6" ||
                                                // this.state.kultura_grupa == "169" ||
                                                // this.state.kultura_grupa == "201" ||
                                                // this.state.kultura_grupa == "203" ||
                                                // this.state.kultura_grupa == "204" ||
                                                // this.state.kultura_grupa == "210" ||
                                                // this.state.kultura_grupa == "211"
                                            ) &&
                                            <>
                                                <div style={{ width: '80px', marginLeft: '16px' }}>{i18n.t('Number of rows')}</div>
                                                <div style={{ width: '80px', marginLeft: '16px', textAlign: 'center' }}>{i18n.t('Number of seedlings label full')}</div>
                                            </>
                                        }                              
                                                                     
                                </div>}
                                {this.state.kultura_sorta.map((sorta, index) => {
                                    return <div key={index} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <span style={{ width: '200px', fontSize: '15px' }}>{sorta.naziv}</span>

                                        <TextField
                                            placeholder={''}
                                            type="number"
                                            pattern="[0-9]*"
                                            value={sorta.povrsina === null ? 0 : sorta.povrsina}
                                            onFocus={(e) => e.target.select()}
                                            style={{ width: '100px', marginLeft: '10px', marginTop: '15px' }}
                                            onChange={(e) => {

                                                const inputValue = e.target.value;

                                                const parsedValue = inputValue === "" ? null : parseFloat(parseFloat(inputValue).toFixed(4));

                                                this.updatePovrsina(sorta.id_kultura_sorta, (parsedValue < 0 ? 0 : parsedValue));
                                            }}
                                            disabled={this.props.isPreview} >


                                        </TextField>
                                        {
                                            (this.state.kultura_sorta.length > 0 
                                                // && this.state.kultura_grupa == "161" ||
                                                // this.state.kultura_grupa == "6" ||
                                                // this.state.kultura_grupa == "169" ||
                                                // this.state.kultura_grupa == "201" ||
                                                // this.state.kultura_grupa == "203" ||
                                                // this.state.kultura_grupa == "204" ||
                                                // this.state.kultura_grupa == "210" ||
                                                // this.state.kultura_grupa == "211"
                                            ) &&
                                            <>
                                                <div className='custom_number_field'>{sorta.raspored_redova.length}</div>
                                                <div className='custom_number_field'>{sorta.raspored_redova.reduce((sum, red) => {
                                                    return sum + red.broj_sadnica
                                                }, 0)}</div>
                                            </>

                                        }
                                    </div>
                                })}
                                {(this.state.kultura_sorta.length > 0) && <div style={{ marginBottom: '20px', fontSize: '13px', display: 'flex', justifyContent: 'flex-end', opacity: '0.6' }}>
                                <div style={{ width: '150px', paddingLeft: '100px', boxSizing: 'border-box' }}> Ukupno:</div> <div style={{ width: '160px', marginLeft: '9px' }}>{this.state.totalHybridArea.toFixed(4)} od {(this.state.povrsina === null || this.state.povrsina === "") ? (0).toFixed(4) : parseFloat(this.state.povrsina).toFixed(4)} (ha)</div>

                            </div>
                            } 
                            </div>
                                        {
                                            this.state.kultura_sorta.length > 0 &&
                                            <div>
                                                <div className='arrangement_checkbox_with_info'>
                                                    <FormGroup>
                                                        <FormControlLabel control={<Checkbox
                                                            checked={this.state.koristi_redove} onChange={() => this.toggleKoristiRedove()}></Checkbox>} label={i18n.t('Show rows and sortiment placement')}
                                                            style={{ width: '400px', marginLeft: '-15px', marginBottom: '5px', }}
                                                            disabled={this.props.isPreview}></FormControlLabel>
                                                    </FormGroup>
                                                    <CustomTooltip title={i18n.t('Show rows and sortiment placement tooltip')}>
                                                        <img src="./info-icon.svg" height='21px' style={{marginBottom: '8px', marginLeft: '-25px'}} />
                                                    </CustomTooltip>                                                    
                                                </div>                                                   
                                                {this.state.koristi_redove && (
                                                    <div style={{ display: 'flex' }}>
                                                        <TextField
                                                            placeholder={''}
                                                            type="number"
                                                            pattern="[0-9]*"
                                                            value={this.state.broj_redova}
                                                            label={i18n.t('Total rows')}
                                                            style={{ width: '280px', marginTop: '20px' }}
                                                            onChange={(e) => {
                                                                const inputValue = e.target.value;
                                                                console.log("input", inputValue);
                                                                if(inputValue == 0){
                                                                    console.log("true");
                                                                    this.setState({ disableArrange: true });
                                                                }
                                                                else{
                                                                    console.log("false");
                                                                    this.setState({ disableArrange: false });
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                const inputValue = e.target.value;
                                                                //this.setState({ raspored_redova: inputValue });
                                                                const parsedValue = inputValue === "" ? null : parseFloat(parseFloat(inputValue).toFixed(4));
                                                                this.updateBrojRedova((parsedValue < 0 ? 0 : parsedValue));
                                                            }}
                                                            onInput={(e) => {
                                                                const inputValue = e.target.value;
                                                                this.setState({ broj_redova: inputValue });
                                                            }}
                                                            onFocus={(e) => e.target.select()}
                                                            disabled={this.props.isPreview}
                                                        />
                                                        <button
                                                            className={'arrange_seedlings_button'}
                                                            variant="contained"
                                                            onClick={() => {
                                                                console.log('this.state.raspored_redova.length', this.state.raspored_redova.length);
                                                                this.setState({
                                                                    arrange_seedlings: !this.state.arrange_seedlings,
                                                                });
                                                                //this.makeEditRows(this.state.broj_redova);
                                                            }}
                                                            disabled={this.state.disableArrange}
                                                            //disabled={this.state.raspored_redova === null || this.state.raspored_redova.length === 0 || this.state.broj_redova === 0}
                                                        >
                                                            {i18n.t('Arrange seedlings')}
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        }

                            </div>
                                </div>     

                            
                            </>}
                                                      
                            <div style={{ display: 'flex' }}>
                                {this.state.edit &&
                                    <Button style={{ marginLeft: '10px', marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                                        this.archiveById(this.props.data.id);
                                    }}
                                    disabled={this.props.isPreview}>
                                        {i18n.t('Archive field')}
                                    </Button>
                                }
                                <Button style={{ marginLeft: '10px', marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                                this.setState({ crop_rotation: true }, () => {
                                    axios.get('api/crop/kultura_klijent')
                                        .then(res => this.setState({ cr_crops: res.data.data }))
                                        .catch(err => console.log(err))
                                })
                            }}>{i18n.t('Crop rotation')}
                            </Button>
                                
                                    <Button style={{ marginLeft: 'auto', marginTop: '20px' }} variant="contained" color="primary" onClick={() => {
                                        this.state.editGeom ? this.updateGeom() : this.addField();
                                    }}
                                    disabled={this.props.isPreview}>
                                        {this.state.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                
                            {this.state.alert === true &&
                                <AlertArchive
                                    handleCloseAlert={this.handleCloseAlert}
                                    addAfterCulture={this.addAfterCulture}
                                    closeEditDialog={this.closeEditDialog}
                                    data={this.state.data}
                                    alert={true}
                                    close={true}
                                    handleOpenAlert={this.handleOpenAlert} />}

                        </div>
                        <Dialog
                            open={this.state.crop_rotation}
                            onClose={() => { this.setState({ crop_rotation: false }) }}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle maxWidth="sm" disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.state.edit ? i18n.t('Crop rotation') : i18n.t('Crop rotation')}
                                <IconButton onClick={() => { this.setState({ crop_rotation: false }) }}>
                                    <span className="icon-cancel-circle closeIcon" />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent style={{ display: 'grid' }}>
                                <TextField
                                    label={i18n.t('Preceding crop')}
                                    select
                                    value={this.state.cr_crop}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '512px', marginLeft: '10px', marginTop: '20px', marginBottom: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ cr_crop: e.target.value }, () => {
                                            if (this.state.cr_crop !== null && this.state.kultura !== null) {
                                                this.getCropRotation(this.state.kultura, this.state.cr_crop);
                                            }
                                        });
                                    }}
                                    disabled={this.props.isPreview}>
                                    <option value='' />
                                    {this.state.cr_crops.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
                                </TextField>
                                <TextField
                                    label={i18n.t('Crop group')}
                                    select
                                    value={this.state.kultura_grupa}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '512px', marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura_grupa: e.target.value }, () => {
                                            axios.get('api/crop_subgroup/kultura_podgrupa_klijent',
                                                { params: { id_grupa: this.state.kultura_grupa } }
                                            )
                                                .then(res => { this.setState({ cropSubgroupType: res.data.data, kpg_disabled: false }); })
                                                .catch(err => { console.log(err) })
                                        });
                                    }}
                                    disabled={this.props.isPreview} >
                                    <option value='' />
                                    {this.state.cropGroupType.map((cg) => <option key={cg.id} value={cg.id}>{cg.naziv}</option>)}
                                </TextField>
                                <TextField
                                    label={i18n.t('Crop subgroup')}
                                    select
                                    disabled={this.state.kpg_disabled || this.props.isPreview}
                                    value={this.state.kultura_podgrupa}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '512px', marginBottom: '10px', marginLeft: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura_podgrupa: e.target.value }, () => {
                                            axios.get('api/crop/kultura_klijent',
                                                { params: { id_podgrupa: this.state.kultura_podgrupa } }
                                            )
                                                .then(res => { this.setState({ cropType: res.data.data, kultura_disabled: false }); })
                                                .catch(err => { console.log(err) })
                                        });
                                    }} >
                                    <option value='' />
                                    {this.state.cropSubgroupType.map((csg) => <option key={csg.id} value={csg.id}>{csg.naziv}</option>)}
                                </TextField>
                                <TextField
                                    label={i18n.t('Crop')}
                                    select
                                    disabled={this.state.kultura_disabled || this.props.isPreview}
                                    value={this.state.kultura}
                                    SelectProps={{ native: true, }}
                                    style={{ width: '512px', marginLeft: '10px', marginRight: '10px' }}
                                    onChange={(e) => {
                                        this.setState({ kultura: e.target.value }, () => {
                                            if (this.state.cr_crop !== null && this.state.kultura !== null) {
                                                this.getCropRotation(this.state.kultura, this.state.cr_crop);
                                            }
                                        });
                                    }}>
                                    <option value='' />
                                    {this.state.cropType.map((c) => <option key={c.id} value={c.id}>{c.naziv}</option>)}
                                </TextField>
                                <DialogContentText style={{ marginLeft: '10px', marginTop: '20px' }}>
                                    {i18n.t('Crop rotation is the practice of growing different crops in successive seasons on the same land, in order to preserve the productive capacity of the soil.')}
                                </DialogContentText>
                                {Object.keys(this.state.crop_rotation_tip).length > 0 && this.state.cr_crop !== null && this.state.kultura !== null &&
                                    <DialogContentText className="crop-rotation-info" style={{ width: '512px', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
                                        {i18n.t('Crop rotation') + ' : ' + this.state.crop_rotation_tip[0].naziv}
                                    </DialogContentText>
                                }
                                {Object.keys(this.state.crop_rotation_tip).length === 0 && this.state.cr_crop !== null && this.state.kultura !== null &&
                                    <DialogContentText className="crop-rotation-info" style={{ width: '512px', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
                                        {i18n.t('There is no crop rotation for a selected culture and preculture.')}
                                    </DialogContentText>
                                }
                                    <span style={{ color: 'red' }}>Kultura sorta broj redova: {this.state.kultura_sorta.broj_redova}</span>
                                </DialogContent>
                            </Dialog>
                        </> :
                            <ArrangeSeedlings
                                ref={this.arrangementComponent}
                                raspored_redova={this.state.raspored_redova}
                                broj_redova={this.state.broj_redova}
                                sorte={this.state.kultura_sorta}
                                saveSeedlingsArrangement={this.saveSeedlingsArrangement}
                                editDisabled={this.props.isPreview}
                                id_table={this.props.data.id}
                            />
                        }
                    </DialogContent>
                    {this.state.arrange_seedlings && <DialogActions style={{marginBottom: '16px', marginRight: '16px'}}>
                        <Button style={{ marginTop: '20px', marginLeft: "32px" }} variant="contained" color="primary"
                            onClick={() => {
                                this.setState({
                                    arrange_seedlings: false
                                })
                            }}>
                            {i18n.t('Back')}
                        </Button>
                        <Button style={{ marginLeft: '10px', marginTop: '20px', marginLeft: "auto" }} variant="contained" color="primary"
                            onClick={() => {
                                this.arrangementComponent.current.saveSeedlingsArrangement()
                            }}
                            disabled={this.props.isPreview}>                        
                            {i18n.t('Arrange')}
                        </Button>
                    </DialogActions>}
                </Dialog>
                <RemoveSort
                    isOpen={this.state.isRemoveSortModalOpen}
                    handleConfirm={() => {
                        if (this.state.sortRemovalPendingAction) {
                            this.state.sortRemovalPendingAction(this.state.sortRemovalNewIds)
                        }
                        this.setState({ isRemoveSortModalOpen: false })
                        this.setState({ sortRemovalPendingAction: null })
                        this.setState({ sortRemovalNewIds: [] })

                    }}
                    handleClose={() => {
                        this.setState({ isRemoveSortModalOpen: false })
                        this.setState({ sortRemovalPendingAction: null })
                        this.setState({ sortRemovalNewIds: [] })
                    }} />
            </div >
        );
    };
};